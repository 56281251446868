define('hotel-husafell/components/husafell-table', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    classNames: ['husafell-table'],

    actions: {
      performAction: function performAction(params) {
        this.action(params);
      },
      selectDateTo: function selectDateTo(date) {
        if (date) {
          this.set('dateTo', (0, _moment.default)(date).format('YYYY-MM-DD'));
        }
      },
      selectDateFrom: function selectDateFrom(date) {
        if (date) {
          this.set('dateFrom', (0, _moment.default)(date).format('YYYY-MM-DD'));
        }
      }
    }
  });
});