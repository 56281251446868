define('hotel-husafell/routes/webstore/detail', ['exports', 'hotel-husafell/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var URI = _environment.default.URI;
  exports.default = Ember.Route.extend({
    model: function model(params) {
      this.set('paramsId', params.id);
      return this.store.findRecord('gift-package', params.id, { reload: true });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);
      this.setHeadTags(model);
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      var title = 'Hótel Húsafell Gjafabréf: ' + model.get('title') + '\n';
      controller.set('facebookLink', encodeURI('https://www.facebook.com/sharer/sharer.php?u=' + URI + '/' + this.paramsId));
      controller.set('twitterLink', encodeURI('https://twitter.com/intent/tweet?text=' + title + '&url=' + URI + '/' + this.paramsId + '&via=HotelHusafell'));
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        Ember.run.cancel(this.scheduler);
      }
    },
    setHeadTags: function setHeadTags(model) {
      var headTags = [{
        type: 'meta',
        tagId: 'meta-og-description',
        attrs: {
          name: 'og:description',
          content: model.get('description')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-url',
        attrs: {
          name: 'og:url',
          content: URI + '/' + this.get('paramsId')
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-title',
        attrs: {
          name: 'og:title',
          content: 'Hótel Húsafell - ' + model.get('title') + ' - Gjafabréf'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-type',
        attrs: {
          name: 'og:type',
          content: 'product'
        }
      }, {
        type: 'meta',
        tagId: 'meta-og-image',
        attrs: {
          name: 'og:image',
          content: model.thumbnails.firstObject.url
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-card',
        attrs: {
          name: 'twitter:card',
          content: 'summary_large_image'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-image',
        attrs: {
          name: 'twitter:image',
          content: model.thumbnails.firstObject.url
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-site',
        attrs: {
          name: 'twitter:site',
          content: '@HotelHusafell'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-title',
        attrs: {
          name: 'twitter:title',
          content: 'Hótel Húsafell - ' + model.get('title') + ' - Gjafabréf'
        }
      }, {
        type: 'meta',
        tagId: 'meta-twitter-description',
        attrs: {
          name: 'twitter:description',
          content: model.get('description')
        }
      }];

      this.set('headTags', headTags);
    },


    actions: {
      didTransition: function didTransition(transition) {
        this.scheduler = Ember.run.later(this, function () {
          this.controller.handleResize();
        }, 200);
      },
      error: function error(_error) {
        if (_error.errors.firstObject.message === 'Pakki fannst ekki') {
          this.flashMessages.warning(_error.errors.firstObject.message);
          this.replaceWith('webstore');
        } else {
          return true;
        }
      }
    }
  });
});