define('hotel-husafell/controllers/admin/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['filter', 'search', 'page', 'from', 'to'],
    page: 0,
    filter: null,
    from: (0, _moment.default)().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
    to: (0, _moment.default)().format("YYYY-MM-DD"),

    init: function init() {
      this._super.apply(this, arguments);
      this.modalIcons = [{ title: 'Vörur', img: '../img/icons/visualization.svg' }, { title: 'Gjafabréf', img: '../img/icons/visualization.svg' }, { title: 'Birta á vef', img: '../img/icons/visualization.svg' }];
      this.tableHeaders = ["Nr.", "Dags.", "Greiðandi", "Handhafi", "Verð", "Nr. FG", "Selt af", "Staða"];
    },


    observeSearch: Ember.observer('search', 'filter', 'from', 'to', function () {
      this.set('page', 0);
    }),

    tableActions: Ember.computed('filter', function () {
      return [{ text: 'Öll kort', action: 'setFilter', param: null, class: this.get('filter') === null ? 'btn btn-white active' : 'btn btn-white' }, { text: 'Ónotuð kort', action: 'setFilter', param: 'UNUSED', class: this.get('filter') === 'UNUSED' ? 'btn btn-white active' : 'btn btn-white' }, { text: 'Notuð kort', action: 'setFilter', param: 'USED', class: this.get('filter') === 'USED' ? 'btn btn-white active' : 'btn btn-white' }];
    }),

    actions: {
      setFilter: function setFilter(filter) {
        this.set('filter', filter);
      }
    }
  });
});