define('hotel-husafell/controllers/admin/products/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['search', 'page'],
    page: 0,

    init: function init() {
      this._super.apply(this, arguments);
      this.tableHeaders = ["Nr.", "Dagur", "Flokkur", "Nafn vöru", "Lýsing vöru", "Verð"];
    },


    observeSearch: Ember.observer('search', function () {
      this.set('page', 0);
    }),

    actions: {
      createProduct: function createProduct() {
        this.transitionToRoute("admin.products.create-product");
      }
    }
  });
});