define('hotel-husafell/components/x-text-editor', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    classNames: ["text-editor"],
    isInEditMode: false,
    temporaryText: "",

    didRender: function didRender() {
      if (this.multiLine) {
        $("#" + this.elementId + " textarea").focus();
      } else {
        // works for the text and number input
        $("#" + this.elementId + " input").focus();
      }
    },


    actions: {
      toggleEditMode: function toggleEditMode() {
        if (this.editable) {
          this.set("isInEditMode", true);
          this.set("temporaryText", this.text);
        }
      },
      commitEdit: function commitEdit() {
        this.set("isInEditMode", false);
        this.set("text", this.temporaryText.trim());
      }
    }
  });
});