define('hotel-husafell/services/error-handler', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    flashMessages: Ember.inject.service(),
    router: Ember.inject.service(),
    generalErrorMessage: 'Óþekkt villa kom upp.',

    handleError: function handleError(err, status) {
      var _this = this;

      var authorized = !(status === 403 || status === 401);
      if (err && Array.isArray(err.errors)) {
        err.errors.map(function (e) {
          if (e.status === 403 || e.status === 401) {
            authorized = false;
          } else {
            if (e.message) {
              _this.flashMessages.warning(e.message);
            } else {
              _this.flashMessages.warning(_this.generalErrorMessage);
            }
          }
        });
      } else {
        if (authorized) {
          this.flashMessages.warning(err.message && err.status !== 500 ? err.message : this.generalErrorMessage);
        }
      }

      if (!authorized) {
        this.router.transitionTo('login');
      }
    }
  });
});