define("hotel-husafell/components/x-tag-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["tag-editor"],
    isInEditMode: false,
    temporaryText: "",

    didRender: function didRender() {
      $("#" + this.elementId + " input").focus();
    },


    actions: {
      toggleEditMode: function toggleEditMode() {
        if (this.editable) {
          this.set("isInEditMode", true);
          this.set("temporaryText", "");
        }
      },
      toggleOut: function toggleOut() {
        this.set('temporaryText', "");
        this.toggleProperty("isInEditMode");
      },
      removeTag: function removeTag(tag) {
        this.get("tags").removeObject(tag);
      },
      scanForEnterAndCommitEdit: function scanForEnterAndCommitEdit() {
        if (event.which === 13 || event.keyCode === 13) {
          var newTag = this.temporaryText.trim();
          if (newTag.length > 0) {
            if (this.tags.includes(newTag)) {
              this.flashMessages.warning("Þú hefur þegar valið '" + newTag + "'");
            } else {
              this.get("tags").pushObject(newTag);
            }
          }
          this.set('temporaryText', "");
        }
      }
    }
  });
});