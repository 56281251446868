define('hotel-husafell/routes/admin/products/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      search: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel() {
      this.store.unloadAll("product");
      this.controllerFor('admin.products.index').set('isLoading', true);
    },
    model: function model(params) {
      return this.store.query('product', {
        search: params.search ? params.search : null,
        page: params.page ? params.page : 0
      });
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('admin.products.index').set('isLoading');
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('search');
      }
    }
  });
});