define('hotel-husafell/adapters/application', ['exports', 'ember-data', 'hotel-husafell/config/environment', 'ember-local-storage'], function (exports, _emberData, _environment, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RESTURL = _environment.default.RESTURL,
      CMS_NAMESPACE = _environment.default.CMS_NAMESPACE,
      WEBSTORE_NAMESPACE = _environment.default.WEBSTORE_NAMESPACE;
  exports.default = _emberData.default.RESTAdapter.extend({

    host: RESTURL,
    apiManager: Ember.inject.service(),
    errorHandler: Ember.inject.service(),
    currentUser: (0, _emberLocalStorage.storageFor)('current-user'),

    namespace: Ember.computed('apiManager.isWebstore', function () {
      return this.get('apiManager.isWebstore') ? WEBSTORE_NAMESPACE : CMS_NAMESPACE;
    }),

    headers: Ember.computed('currentUser.token', function () {
      return {
        "Accept": "application/json, text/javascript, */*; q=0.01",
        "Content-Type": "application/json",
        "Authorization": this.get('currentUser.token')
      };
    }),

    ajaxOptions: function ajaxOptions(url, type, options) {
      var methodType = type === 'PUT' ? 'PATCH' : type;
      return this._super(url, methodType, options);
    },
    isInvalid: function isInvalid(status, headers, payload) {
      this.get('errorHandler').handleError(payload, status);
      return this._super.apply(this, arguments);
    },
    pathForType: function pathForType(type) {
      if (type === 'cart') {
        return Ember.String.singularize(type);
      }
      return this._super.apply(this, arguments);
    }
  });
});