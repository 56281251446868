define('hotel-husafell/components/x-nav-admin', ['exports', 'hotel-husafell/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var rootURL = _environment.default.rootURL;
  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    tagName: 'nav',
    classNames: ['x-nav'],
    ariaRole: 'navigation',
    router: Ember.inject.service(),

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;
      $('.x-nav-admin .links .link').each(function () {
        if ($(this).hasClass('active') && !$(this).hasClass('logout')) {
          $('.x-nav-admin .links').prepend($('<span class="active-link"></span>'));
          $('.x-nav-admin .active-link').css({ 'top': Math.round($(this).position().top) + 'px' });
        }
        if (!$(this).hasClass('logout')) {
          $(this).hover(function () {
            $('.x-nav-admin .active-link').css("opacity", 1);
            $('.x-nav-admin .active-link').stop(true, false).animate({ top: Math.round($(this).position().top) + 'px' }, "slow");
          }, function () {
            $('.x-nav-admin .active-link').fadeTo("fast", 0);
          });
        }
      });

      $('.x-nav-admin .hamburger').click(function (e) {
        $('body').toggleClass('no-scroll');
        if (!$('body').hasClass('no-scroll')) {
          self.runLater = Ember.run.later(function () {
            $('.x-nav-admin .mobile-menu .logo').removeClass('fade-out').addClass('fade-in');
            $('.x-nav-admin .mobile-links-container').removeClass('animated show slideOutLeft');
          }, 500);
          $('.x-nav-admin .mobile-links-container').removeClass('slideInLeft').addClass('slideOutLeft');
          document.ontouchmove = function (e) {
            return true;
          };
        } else {
          document.ontouchmove = function (event) {
            event.preventDefault();
          };
          $('.x-nav-admin .mobile-links-container').addClass('animated show slideInLeft');
          $('.x-nav-admin .mobile-menu .logo').removeClass('fade-in').addClass('fade-out');
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.runLater);
      $('.x-nav-admin .links .link').each(function () {
        $(this).off('mouseenter mouseleave');
      });
    },


    actions: {
      logOut: function logOut() {
        this.get("api").logout();
        this.get('router').transitionTo('login');
      }
    }
  });
});