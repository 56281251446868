define('hotel-husafell/initializers/resize', ['exports', 'ember-resize/services/resize', 'hotel-husafell/config/environment'], function (exports, _resize, _environment) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.initialize = initialize;
    function initialize(application) {
        var resizeServiceDefaults = Ember.getWithDefault(_environment.default, 'resizeServiceDefaults', {
            debounceTimeout: 200,
            heightSensitive: true,
            widthSensitive: true
        });
        var injectionFactories = Ember.getWithDefault(resizeServiceDefaults, 'injectionFactories', ['view', 'component']) || [];
        application.unregister('config:resize-service');
        application.register('config:resize-service', resizeServiceDefaults, { instantiate: false });
        application.register('service:resize', _resize.default);
        var resizeService = application.resolveRegistration('service:resize');
        resizeService.prototype.resizeServiceDefaults = resizeServiceDefaults;
        injectionFactories.forEach(function (factory) {
            application.inject(factory, 'resizeService', 'service:resize');
        });
    }
    exports.default = {
        initialize: initialize,
        name: 'resize'
    };
});