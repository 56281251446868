define("hotel-husafell/helpers/sum", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.sum = sum;
  function sum(params) {
    return params.reduce(function (a, b) {
      // While a and b are undefined
      if (!a && a != 0 || !b && b != 0) return "-";
      return a + b;
    });
  }

  exports.default = Ember.Helper.helper(sum);
});