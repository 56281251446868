define('hotel-husafell/models/gift-card', ['exports', 'ember-data', 'ember-api-actions'], function (exports, _emberData, _emberApiActions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    active: attr('boolean'),
    createdAt: attr('string'),
    createdBy: attr('string'),
    usedBy: attr('string'),
    usedAt: attr('string'),
    validTo: attr('string'),
    validFrom: attr('string'),
    payer: attr('string'),
    receiver: attr('string'),
    price: attr('number'),
    giftCardNumber: attr('string'),
    fgNumber: attr('string'),
    state: attr('string'),
    usedDate: attr('string'),
    title: attr('string'),
    description: attr('string'),
    productCounts: hasMany('product-count'),
    discount: attr('number'),
    paymentMethod: attr('string'),
    notes: attr('string'),

    setUsedGiftcard: (0, _emberApiActions.memberAction)({
      path: 'use',
      type: 'post'
    })
  });
});