define('hotel-husafell/controllers/admin/products/view-product', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    hasArrayChange: Ember.computed('model.images.@each', 'model.features.@each', 'initialImageArray', 'initialFeatureArray', function () {
      var currentImages = JSON.stringify(this.get('model.images').toArray());
      var currentFeatures = JSON.stringify(this.get('model.features').toArray());
      return currentImages !== this.initialImageArray || currentFeatures !== this.initialFeatureArray;
    }),

    actions: {
      saveProduct: function saveProduct() {
        var _this = this;

        this.set('isLoading', true);
        this.get("model").save().then(function () {
          _this.flashMessages.success("Vöru hefur verið breytt");
          _this.set('initialImageArray', JSON.stringify(_this.get('model.images').toArray()));
          _this.set('initialFeatureArray', JSON.stringify(_this.get('model.features').toArray()));
        }).finally(function () {
          _this.set('isLoading', false);
        });
      },
      continueWithoutSave: function continueWithoutSave() {
        this.set('leaveWithoutSave', true);
        this.set('confirmLeave', false);
        this.get('model').rollbackAttributes();
        this.get('previousTransition').retry();
      },
      continueEditing: function continueEditing() {
        this.set('confirmLeave', false);
      }
    }
  });
});