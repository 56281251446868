define('hotel-husafell/controllers/webstore/cart', ['exports', 'hotel-husafell/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Controller.extend({
    isPayButtonDisabled: false,

    init: function init() {
      this._super.apply(this, arguments);
      $(window).on('resize', this.handleResize.bind(this));
    },


    totalPrice: Ember.computed('model.cartItems.@each.count', function () {
      var sum = 0;
      this.get('model.cartItems').forEach(function (item) {
        sum += item.get('giftPackage.price');
      });
      return sum;
    }),

    isCartEmpty: Ember.computed('model.cartItems.length', function () {
      return !this.get('model.cartItems.length');
    }),

    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      $(window).off('resize');
    },
    handleResize: function handleResize() {
      if ($(window).width() <= 768) {
        this.set('isMobile', true);
      } else {
        this.set('isMobile');
      }

      if ($('.main').width() <= 650 || this.get('isMobile')) {
        $('.summary').addClass('full-width');
      } else {
        $('.summary').removeClass('full-width');
      }
    },


    actions: {
      startCheckout: function startCheckout() {
        this.set('getUserDetails', true);
      },
      startTransaction: function startTransaction() {
        var _this = this;

        if (this.get('model').validate()) {
          this.api.startCheckout().then(function (result) {
            _this.set("paymentProductDetails", result.token.productDetails);
            _this.set("paymentMerchant", result.token.merchantId);
            _this.set("paymentCurrency", result.token.currency);
            _this.set("paymentLanguage", result.token.language);
            _this.set("paymentAuthorization", result.token.authorizationOnly);
            _this.set("paymentReference", result.token.referenceNumber);
            _this.set("paymentChecksum", result.token.digitalSignature);
            _this.set("paymentValitorURL", result.token.valitorUrl);
            _this.set("paymentSuccessfulAutomaticRedirect", result.token.paymentSuccessfulAutomaticRedirect);
            _this.set("paymentExpireUrl", result.token.sessionExpiredUrl);
            _this.set("paymentCancelUrl", result.token.paymentCanceledUrl);
            _this.set("paymentSuccessUrl", result.token.successfulShopCallbackUrl);
            _this.set("paymentSuccessUrlText", result.token.paymentSuccessfulURLText);
            _this.set("paymentSuccessfulServerUrl", result.token.successfulServerCallbackUrl);
            _this.set("isPayButtonDisabled", true);
            // TODO: make this more nice and solve this here without a timeout
            setTimeout(function () {
              return $("#valitorForm").submit();
            }, 1000);
          }).catch(function (error) {
            console.log(error);
            _this.flashMessages.warning("Afsakið, eitthvað fór úrskeðis. Vinsamlegast reynið aftur.");
          });
        } else {
          this.flashMessages.warning("Vinsamlegast farðu aftur yfir formið.");
        }
      },
      clearCart: function clearCart() {
        this.cartHandler.clearCart();
      }
    }
  });
});