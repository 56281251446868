define('hotel-husafell/controllers/webstore/detail', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    amount: 1,

    init: function init() {
      this._super.apply(this, arguments);
      $(window).on('resize', this.handleResize.bind(this));
    },
    willDestroy: function willDestroy() {
      this._super.apply(this, arguments);
      $(window).off('resize');
    },
    handleResize: function handleResize() {
      if ($(window).width() <= 768) {
        this.set('isMobile', true);
      } else {
        this.set('isMobile');
      }

      if (!this.get('isMobile')) {
        $('.detail .images').height(Math.round($('.detail .detail-content').height()));
      } else {
        $('.detail .images').height('');
      }
    },


    actions: {
      increaseAmount: function increaseAmount() {
        this.set("amount", this.amount + 1);
      },
      decreaseAmount: function decreaseAmount() {
        if (this.amount === 1) {
          return;
        }
        this.set("amount", this.amount - 1);
      },
      addToCart: function addToCart() {
        for (var i = 0; i < this.amount; i++) {
          var newCartItem = this.store.createRecord("cart-item");
          newCartItem.set("giftPackage", this.model);

          this.cartHandler.addToCart(newCartItem);
        }
        this.set("amount", 1);
      }
    }
  });
});