define('hotel-husafell/controllers/admin/giftcards/create-giftcard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    saveModel: function saveModel() {
      var _this = this;

      this.set('isLoading', true);
      this.get("model.giftcard").save().then(function () {
        _this.flashMessages.success("Gjafabréf hefur verið búið til");
        _this.cleanUpNewProductCountsRecords();
        _this.transitionToRoute("admin.giftcards.index");
      }).finally(function () {
        _this.set('isLoading');
      });
    },
    cleanUpNewProductCountsRecords: function cleanUpNewProductCountsRecords() {
      var _this2 = this;

      this.store.peekAll("product-count").forEach(function (d) {
        if (d.isNew) {
          _this2.set("model.giftcard.productCounts", _this2.get("model.giftcard.productCounts").without(d));
          _this2.store.unloadRecord(d);
        }
      });
    },


    actions: {
      saveGiftcard: function saveGiftcard() {
        this.set("model.active", true);
        this.saveModel();
      }
    }
  });
});