define('hotel-husafell/services/cart-handler', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    storedCart: (0, _emberLocalStorage.storageFor)('current-cart'),
    store: Ember.inject.service(),
    apiManager: Ember.inject.service(),

    cartObserver: Ember.observer("cart.cartItems.@each.receiver", "cart.payerEmail", "cart.payerName", function () {
      if (!this.get("currentlyClearingCart")) {
        this.synchronizeCartToLocalStorage();
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.get('apiManager').setWebstoreMode(true);
      if (this.get("storedCart.cartItems")) {
        this.synchronizeLocalStorageToCart();
      }
    },


    // Creates cart based on local storage object
    synchronizeLocalStorageToCart: function synchronizeLocalStorageToCart() {
      var _this = this;

      this.store.unloadAll();
      this.set("cart", this.store.createRecord("cart", { 'cartItems': [] }));

      if (this.get('storedCart.cartItems.length')) {
        this.get('storedCart.cartItems').forEach(function (item) {
          var cartItem = _this.store.createRecord('cart-item');
          _this.store.findRecord('gift-package', item.giftPackage).then(function (res) {
            // Explanation for the following if-statement:
            // We need to check if the object has been destroyed while we were waiting for an answer from the backend/store, because
            // this function can be called as often as one wished and we unloadAll everytime we call this function.
            // So to be on the safe side and to also avoid exceptions, we need to check if our cartItem is still alive before
            // we try to set anything on it.

            if (!cartItem.isDestroyed) {
              cartItem.set('giftPackage', res);
              cartItem.set('receiver', item.receiver);
              _this.get('cart.cartItems').pushObject(cartItem);
            }
          });
        });
      }

      this.set("cart.payerEmail", this.get("storedCart.payerEmail"));
      this.set("cart.payerName", this.get("storedCart.payerName"));
    },


    // Creates local storage object based on cart
    synchronizeCartToLocalStorage: function synchronizeCartToLocalStorage() {
      var _this2 = this;

      this.get("storedCart").reset();

      if (this.get('cart.cartItems.length')) {
        this.set("storedCart.cartItems", []);
        this.get('cart.cartItems').forEach(function (item) {
          var newCartItem = {
            giftPackage: item.giftPackage.id,
            receiver: item.receiver
          };
          _this2.get("storedCart.cartItems").push(newCartItem);
        });
      }

      this.set("storedCart.payerEmail", this.get("cart.payerEmail"));
      this.set("storedCart.payerName", this.get("cart.payerName"));
    },
    clearCart: function clearCart() {
      this.set("currentlyClearingCart", true);

      this.get("storedCart").reset();
      this.synchronizeLocalStorageToCart();

      this.set("currentlyClearingCart", false);
    },
    removeFromCart: function removeFromCart(item) {
      this.get("cart.cartItems").removeObject(item);
      this.synchronizeCartToLocalStorage();
    },
    addToCart: function addToCart(item) {
      this.get("cart.cartItems").addObject(item);
      this.synchronizeCartToLocalStorage();
    },
    getCart: function getCart() {
      return this.get("cart");
    },
    getCartAsJson: function getCartAsJson() {
      return this.get("storedCart.content");
    }
  });
});