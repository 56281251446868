define('hotel-husafell/routes/webstore/purchase-success', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    cartHandler: Ember.inject.service(),

    actions: {
      didTransition: function didTransition() {
        this.cartHandler.clearCart();
        return true;
      }
    }
  });
});