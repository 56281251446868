define('hotel-husafell/models/product', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    active: attr('boolean', { defaultValue: true }),
    price: attr('number'),
    description: attr('string'),
    category: attr('string'),
    name: attr('string'),
    createdBy: attr('string'),
    createdAt: attr('string', { defaultValue: moment().format() }),
    type: attr('string'),
    features: attr(),
    images: attr()
  });
});