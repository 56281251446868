define('hotel-husafell/components/x-image-gallery', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["image-gallery"],
    images: [],
    slideIndex: 1,

    didInsertElement: function didInsertElement() {
      this.scheduler = Ember.run.scheduleOnce('afterRender', this, this.showSlides);
    },
    showSlides: function showSlides(n) {
      if (n === undefined) {
        n = this.images.length;
      }
      var i = void 0;
      var slides = $("#" + this.elementId + " .mySlides");
      var dots = $("#" + this.elementId + " .dot");
      if (n > slides.length) {
        this.slideIndex = 1;
      }
      if (n < 1) {
        this.slideIndex = slides.length;
      }
      for (i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }
      slides[this.slideIndex - 1].style.display = "block";

      if (dots.length !== 0) {
        for (i = 0; i < dots.length; i++) {
          dots[i].className = dots[i].className.replace(" active", "");
        }
        dots[this.slideIndex - 1].className += " active";
      }
    },


    actions: {
      plusSlides: function plusSlides(n) {
        this.showSlides(this.slideIndex += n);
      },
      currentSlide: function currentSlide(n) {
        this.showSlides(this.slideIndex = n + 1);
      },
      openFbWindow: function openFbWindow() {
        event.preventDefault();
        window.open($('.facebook').attr('href'), 'DeilaFacebook', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      },
      openTwitterWindow: function openTwitterWindow() {
        event.preventDefault();
        window.open($('.twitter').attr('href'), 'DeilaTwitter', 'height=450, width=550, top=' + ($(window).height() / 2 - 275) + ', left=' + ($(window).width() / 2 - 225) + ', toolbar=0, location=0, menubar=0, directories=0, scrollbars=0');
        return false;
      }
    }
  });
});