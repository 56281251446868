define('hotel-husafell/models/gift-package', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    visible: attr('boolean'),
    createdAt: attr('string'),
    productCounts: hasMany('product-count'),
    createdBy: attr('string'),
    validFrom: attr('string'),
    validTo: attr('string'),
    title: attr('string'),
    description: attr('string'),
    thumbnails: attr(),
    discount: attr('number'),
    price: attr('number'),
    paymentMethod: attr('string'),
    order: attr('number')
  });
});