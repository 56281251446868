define('hotel-husafell/components/x-nav-shop', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({

    tagName: 'nav',
    classNames: ['x-nav'],
    ariaRole: 'navigation',

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.runLater);
      Ember.run.cancel(this.runLaterCartItems);
    }
  });
});