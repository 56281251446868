define('hotel-husafell/routes/admin/view-giftcard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        giftcard: this.store.findRecord('gift-card', params.id, { reload: true }),
        products: this.store.findAll('product')
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('leaveWithoutSave', false);
      if (model.giftcard.state === "USED") {
        controller.set('canChangeState', false);
      } else {
        controller.set('canChangeState', true);
      }
      controller.set("initialProductCountsArray", JSON.stringify(model.giftcard.get("productCounts").toArray()));
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (!this.controller.get('leaveWithoutSave')) {
          if (this.controller.get('model.giftcard.hasDirtyAttributes') || this.controller.get('hasArrayChange')) {
            history.forward();
            this.controller.set('confirmLeave', true);
            this.controller.set('previousTransition', transition);
            transition.abort();
          }
        }
      }
    }
  });
});