define('hotel-husafell/components/x-image-upload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    classNames: ["image-upload"],
    images: [],

    getBase64: function getBase64(file, onLoadCallback) {
      return new Promise(function (resolve, reject) {
        var reader = new FileReader();
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function (event) {
          reject(event);
        };
        reader.readAsDataURL(file);
      });
    },


    actions: {
      openFileSelection: function openFileSelection() {
        $("#" + this.elementId + " input").click();
      },
      removeImage: function removeImage(index) {
        this.get("images").removeAt(index);
      },
      handleFiles: function handleFiles() {
        var _this = this;

        if (this.get("images") === undefined) {
          this.set("images", []);
        }

        var inputElement = $("#" + this.elementId + " input");
        var files = inputElement[0].files;

        for (var i = 0; i < files.length; i++) {
          this.getBase64(files[i]).then(function (result) {
            var fileDefinition = {
              data: result
            };
            _this.get("images").pushObject(fileDefinition);
          });
        }

        inputElement.val("");
      }
    }
  });
});