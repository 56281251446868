define('hotel-husafell/services/api-manager', ['exports', 'hotel-husafell/config/environment', 'ember-local-storage'], function (exports, _environment, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RESTURL = _environment.default.RESTURL,
      CMS_NAMESPACE = _environment.default.CMS_NAMESPACE,
      WEBSTORE_NAMESPACE = _environment.default.WEBSTORE_NAMESPACE;
  exports.default = Ember.Service.extend({
    currentUser: (0, _emberLocalStorage.storageFor)('current-user'),
    isWebstore: false,
    cartHandler: Ember.inject.service(),

    headers: Ember.computed("currentUser.token", function () {
      return {
        "Accept": "application/json, text/javascript, application/pdf, */*; q=0.01",
        "Content-Type": "application/json",
        "Authorization": this.get('currentUser') ? this.get('currentUser.token') : ""
      };
    }),

    request: function request(data) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var headers = data.headers ? data.headers : _this.get('headers');

        var jqxhr = Ember.$.ajax({
          type: data.type ? data.type : 'get',
          url: RESTURL + '/' + (_this.get('isWebstore') ? WEBSTORE_NAMESPACE : CMS_NAMESPACE) + '/' + data.url,
          contentType: 'application/json',
          dataType: data.dataType ? data.dataType : 'json',
          data: JSON.stringify(data.data),
          headers: headers
        }).fail(function (err) {
          if (err.status === 403 || err.status === 401) {
            _this.invalidateSession();
          }
          console.log("failed");
          console.log(err);
          reject(jqxhr.responseJSON);
        }).done(function () {
          resolve(jqxhr.responseJSON);
        });
      });
    },
    getGiftcardDashboardStats: function getGiftcardDashboardStats(dateTo, dateFrom) {
      var data = {
        url: 'giftCards/stats?from=' + dateFrom + '&to=' + dateTo,
        type: 'get'
      };
      return this.request(data);
    },


    // User handling

    isLoggedIn: function isLoggedIn() {
      return this.get('currentUser') && this.get('currentUser.token') !== null;
    },
    login: function login(username, password) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var data = {
          type: 'post',
          url: 'auth/login',
          data: {
            username: username,
            password: password
          }
        };

        _this2.request(data).then(function (response) {
          _this2.set("currentUser.token", response.token);
          resolve();
        }, function (error) {
          reject(error);
        });
      });
    },
    logout: function logout() {
      var _this3 = this;

      var data = {
        type: 'patch',
        url: 'auth/logout',
        headers: this.headers
      };

      return this.request(data).finally(function () {
        _this3.invalidateSession();
      });
    },
    invalidateSession: function invalidateSession() {
      this.get('currentUser').reset();
    },
    sendGiftcardEmail: function sendGiftcardEmail(id, email) {
      var _this4 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var jqxhr = Ember.$.ajax({
          type: 'post',
          url: RESTURL + '/' + CMS_NAMESPACE + "/giftCards/" + id + "/email?email=" + email,
          contentType: 'application/json',
          dataType: null,
          headers: _this4.headers
        }).fail(function (err) {
          reject(jqxhr.responseJSON);
        }).done(function () {
          resolve(jqxhr.responseJSON);
        });
      });
    },
    setWebstoreMode: function setWebstoreMode(isWebstoreMode) {
      this.set("isWebstore", isWebstoreMode);
    },
    startCheckout: function startCheckout() {
      var data = this.cartHandler.getCartAsJson();
      return new Ember.RSVP.Promise(function (resolve, reject) {
        var jqxhr = Ember.$.ajax({
          type: 'post',
          url: RESTURL + '/' + WEBSTORE_NAMESPACE + '/cart',
          contentType: 'application/json',
          dataType: 'json',
          data: JSON.stringify(data)
        }).fail(function (err) {
          reject(jqxhr.responseJSON);
        }).done(function () {
          resolve(jqxhr.responseJSON);
        });
      });
    }
  });
});