define('hotel-husafell/components/husafell-search', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['husafell-search'],
    canReset: true,

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var self = this;
      if (this.get('searchString')) {
        this.set('searchParam', this.get('searchString'));
        this.showInputField();
      }
      $('#search-icon').click(function () {
        self.showInputField();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $('#search-icon').off('click');
    },
    showInputField: function showInputField() {
      $('#search-icon').toggleClass('active');
      this.toggleProperty('canReset');
      if (this.canReset) this.resetSearchString();
      var padding = '20px 10px';
      var searchField = $("input[name='search']");
      if (searchField.css('padding') === '20px 10px') {
        padding = '20px 0px';
      }
      searchField.animate({ width: 'toggle', opacity: 'toggle', padding: padding }, function () {
        if (!this.canReset) $(this).focus();
      });
    },
    resetSearchString: function resetSearchString() {
      this.set('searchParam');
      this.set('searchString');
    },


    actions: {
      setSearchString: function setSearchString() {
        if ((event.which === 13 || event.keyCode === 13) && this.searchParam.trim()) {
          this.set('searchString', this.get('searchParam'));
        }
      }
    }
  });
});