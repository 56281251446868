define('hotel-husafell/routes/webstore/cart', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(transition, params) {
      return this.cartHandler.getCart();
    },
    resetController: function resetController(controller, isExiting, transition) {
      if (isExiting && transition.targetName !== 'error') {
        Ember.run.cancel(this.scheduler);
      }
    },


    actions: {
      didTransition: function didTransition() {
        this.scheduler = Ember.run.later(this, function () {
          this.controller.handleResize();
        }, 200);
      }
    }
  });
});