define('hotel-husafell/initializers/inject-api', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'api', 'service:api-manager');
    application.inject('route', 'api', 'service:api-manager');
    application.inject('component', 'api', 'service:api-manager');
  }

  exports.default = {
    name: 'inject-api',
    initialize: initialize
  };
});