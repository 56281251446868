define('hotel-husafell/initializers/inject-flash-messages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('controller', 'flashMessages', 'service:flash-messages');
    application.inject('route', 'flashMessages', 'service:flash-messages');
    application.inject('component', 'flashMessages', 'service:flash-messages');
  }

  exports.default = {
    name: 'inject-flash-messages',
    initialize: initialize
  };
});