define('hotel-husafell/routes/webstore', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: 'Hótel Húsafell - Vefverslun',

    beforeModel: function beforeModel(transition) {
      this.api.setWebstoreMode(true);
    }
  });
});