define('hotel-husafell/components/husafell-giftcard', ['exports', 'hotel-husafell/config/environment', 'moment'], function (exports, _environment, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var IS_DEV = _environment.default.IS_DEV;
  exports.default = Ember.Component.extend({
    classNames: ['husafell-giftcard'],
    possiblePaymentTypes: [{
      value: "NOT_PAYED",
      label: "Ógreitt",
      default: true
    }, {
      value: "CARD",
      label: "Greitt með korti"
    }, {
      value: "PHONE",
      label: "Símgreiðsla"
    }],

    giftcardRecipientEmail: IS_DEV ? "stokkur@stokkur.is" : "booking@hotelhusafell.is",

    recipientEmailIsValid: Ember.computed("giftcardRecipientEmail", function () {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.giftcardRecipientEmail).toLowerCase());
    }),

    calculatedPrice: Ember.computed("data.discount", "data.productCounts.@each", "data.productCounts.@each.count", function () {
      var totalPrice = 0;
      this.get("data.productCounts").forEach(function (productCount) {
        totalPrice += productCount.count * productCount.get("product.price");
      });

      if (this.data.discount !== undefined) {
        totalPrice -= this.data.discount;
      }

      if (totalPrice < 0) {
        return 0;
      } else {
        return totalPrice;
      }
    }),

    actions: {
      selectValidFrom: function selectValidFrom(date) {
        if (date) {
          this.set('data.validFrom', (0, _moment.default)(date).format("YYYY-MM-DDTHH:mm:ss"));
        }
      },
      selectValidTo: function selectValidTo(date) {
        if (date) {
          this.set('data.validTo', (0, _moment.default)(date).format("YYYY-MM-DDTHH:mm:ss"));
        }
      },
      selectSoldDate: function selectSoldDate(date) {
        if (date) {
          this.set('data.soldDate', (0, _moment.default)(date).format("YYYY-MM-DDTHH:mm:ss"));
        }
      },
      setStatus: function setStatus() {
        this.setCardStatus();
      },
      sendGiftcard: function sendGiftcard() {
        var _this = this;

        if (this.get("recipientEmailIsValid")) {
          this.api.sendGiftcardEmail(this.get("data.id"), this.get("giftcardRecipientEmail")).then(function (d) {
            return _this.flashMessages.success("Gjafabréf hefur verið sent á " + _this.get("giftcardRecipientEmail"));
          }).catch(function (d) {
            return _this.flashMessages.warning("Ekki tókst á senda gjafabréf á " + _this.get("giftcardRecipientEmail"));
          });
        }
      }
    }
  });
});