define('hotel-husafell/controllers/admin/products/create-product', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      saveProduct: function saveProduct() {
        var _this = this;

        this.set('isLoading', true);
        this.get("model").save().then(function () {
          _this.flashMessages.success("Vöru hefur verið búið til");
          _this.transitionToRoute("admin.products.index");
        }).finally(function () {
          _this.set('isLoading');
        });
      }
    }
  });
});