define('hotel-husafell/routes/admin', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    title: 'Hótel Húsafell - Gjafabréf - CMS Kerfi',

    beforeModel: function beforeModel() {
      this.api.set('isWebstore', false);

      if (!this.api.isLoggedIn()) {
        this.transitionTo('login');
      }
    }
  });
});