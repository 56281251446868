define('hotel-husafell/routes/admin/giftcards/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      search: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    beforeModel: function beforeModel() {
      this.store.peekAll('gift-package').map(function (x) {
        return x.unloadRecord();
      }); // https://github.com/emberjs/data/issues/5156#issuecomment-535180317
      this.controllerFor('admin.giftcards.index').set('isLoading', true);
    },
    model: function model(params) {
      return this.store.query('gift-package', {
        search: params.search ? params.search : null,
        page: params.page ? params.page : 0
      });
    },
    afterModel: function afterModel(model, transition) {
      this.controllerFor('admin.giftcards.index').set('isLoading');
    },


    actions: {
      willTransition: function willTransition() {
        this.controller.set('search');
      }
    }

  });
});