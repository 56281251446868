define('hotel-husafell/components/action-btn', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['btn-relative'],
    classNameBindings: ['isLoading:is-loading', 'disabled:disabled:enabled'],
    isLoading: false,
    disabled: false,
    attributeBindings: ['title'],

    click: function click() {
      this.get('action')();
    }
  });
});