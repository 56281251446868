define("hotel-husafell/components/x-choice-editor", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({

    selectionContainsSelected: false,
    value: "",

    didRender: function didRender() {
      if (this.selection) {
        if (this.value) {
          for (var i = 0; i < this.selection.length; i++) {
            if (this.selection[i].value === this.value) {
              this.set("selectionContainsSelected", true);
              break;
            }
          }
        } else {
          for (var _i = 0; _i < this.selection.length; _i++) {
            if (this.selection[_i].default) {
              this.set("value", this.selection[_i].value);
              break;
            }
          }
        }
      }
    },


    actions: {
      changeValue: function changeValue(value) {
        this.set("value", value);
      }
    }
  });
});