define('hotel-husafell/components/x-modal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    classNames: ['x-modal'],

    didInsertElement: function didInsertElement() {
      $('body').addClass('no-scroll');
    },
    didDestroyElement: function didDestroyElement() {
      $('body').removeClass('no-scroll');
    }
  });
});