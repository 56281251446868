define("hotel-husafell/controllers/login", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    username: "",
    password: "",
    errorText: "",

    actions: {
      doLogin: function doLogin() {
        var _this = this;

        this.get("api").login(this.username, this.password).then(function (data) {
          _this.transitionToRoute("admin.index");
        }, function (error) {
          _this.set("errorText", error.errors[0].message);
        });
      },
      resetErrorText: function resetErrorText() {
        this.set("errorText", "");
      }
    }
  });
});