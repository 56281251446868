define('hotel-husafell/components/x-toggle-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["toggle-button"],
    classNameBindings: ['disabled:disabled'],
    id: "",
    toggled: Ember.computed("checked", function () {
      return this.checked ? "checked" : "";
    }),

    didInsertElement: function didInsertElement() {
      // we need a unique id for each instance of a toggle button so that the label element can reference the correct
      // checkbox in the the "for" attribute.
      this.set("id", this.guid());
    },
    guid: function guid() {
      function s4() {
        return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
      }

      return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    },


    actions: {
      toggle: function toggle() {
        this.set("checked", !this.checked);
        if (this.get('hasToggleValue')) this.toggleValue();
      }
    }
  });
});