define('hotel-husafell/components/cart-giftcard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['cart-giftcard'],
    temporaryReceiver: "",

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.handleResize();
      $(window).on('resize', this.handleResize.bind(this));
    },


    temporaryReceiverObserver: Ember.observer('temporaryReceiver', function () {
      if (this.temporaryReceiver && this.temporaryReceiver.length) {
        var str = this.temporaryReceiver;
        var emojiRegex = '(?:[\u2700-\u27BF]|(?:\uD83C[\uDDE6-\uDDFF]){2}|[\uD800-\uDBFF][\uDC00-\uDFFF]|[#-9]\uFE0F?\u20E3|\u3299|\u3297|\u303D|\u3030|\u24C2|\uD83C[\uDD70-\uDD71]|\uD83C[\uDD7E-\uDD7F]|\uD83C\uDD8E|\uD83C[\uDD91-\uDD9A]|\uD83C[\uDDE6-\uDDFF]|[\uD83C\uDE01-\uDE02]|\uD83C\uDE1A|\uD83C\uDE2F|[\uD83C\uDE32-\uDE3A]|[\uD83C\uDE50-\uDE51]|\u203C|\u2049|[\u25AA-\u25AB]|\u25B6|\u25C0|[\u25FB-\u25FE]|\xA9|\xAE|\u2122|\u2139|\uD83C\uDC04|[\u2600-\u26FF]|\u2B05|\u2B06|\u2B07|\u2B1B|\u2B1C|\u2B50|\u2B55|\u231A|\u231B|\u2328|\u23CF|[\u23E9-\u23F3]|[\u23F8-\u23FA]|\uD83C\uDCCF|\u2934|\u2935|[\u2190-\u21FF])';
        str = str.replace(new RegExp(emojiRegex, 'g'), '');
        this.set('temporaryReceiver', str);
      }
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.set("temporaryReceiver", this.model.receiver);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      $(window).off('resize');
    },
    handleResize: function handleResize() {
      $('.cart-giftcard .price').each(function () {
        if ($(window).outerWidth() <= 1200) {
          $(this).css('height', 'auto');
        } else {
          $(this).height(Math.round($(this).siblings('.details').height()));
        }
      });
    },


    actions: {
      setInputFocus: function setInputFocus(e) {
        $('label[for="' + e.target.id + '"]').addClass('active');
        this.set("temporaryReceiver", this.model.receiver);
      },
      removeInputFocus: function removeInputFocus(e) {
        $('label[for="' + e.target.id + '"]').removeClass('active');
        this.set("model.receiver", this.temporaryReceiver ? this.temporaryReceiver.trim() : this.temporaryReceiver);
      },
      deleteCartItem: function deleteCartItem() {
        this.cartHandler.removeFromCart(this.model);
      }
    }
  });
});