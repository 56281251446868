define('hotel-husafell/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      closeCookiesModal: function closeCookiesModal() {
        this.set('showModal');
      }
    }
  });
});