define('hotel-husafell/controllers/admin/giftcards/index', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    queryParams: ['search', 'page'],
    page: 0,

    observeSearch: Ember.observer('search', function () {
      this.set('page', 0);
    }),

    totalProductCount: Ember.computed('model.@each.productCounts.@each.count', function () {
      return this.get('model').map(function (item) {
        var totalCount = 0;
        return item.get('productCounts').map(function (product) {
          return totalCount += product.count;
        }).slice(-1).pop();
      });
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.tableHeaders = ["", "Dags.", "Nafn á gjafabréf", "Vörur", "Verð", "Búið til af", "Staða"];
    },


    actions: {
      navigateToGiftcardCreationScreen: function navigateToGiftcardCreationScreen() {
        this.transitionToRoute("admin.giftcards.create-giftcard");
      },
      navigateToGiftPackageCreationScreen: function navigateToGiftPackageCreationScreen() {
        this.transitionToRoute("admin.giftcards.create-gift-package");
      }
    }
  });
});