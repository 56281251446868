define('hotel-husafell/routes/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      error: function error(_error, transition) {
        if (_error.errors.firstObject.status === '400' || _error.errors.firstObject.status === '404') {
          this.flashMessages.clearMessages();
          this.flashMessages.warning("Þú virðist hafa villst af leið. Við sendum þig aftur á verslun.");
          this.replaceWith('webstore');
        } else {
          return true;
        }
      }
    }
  });
});