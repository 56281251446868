define('hotel-husafell/components/cart-items-count', ['exports', 'ember-local-storage'], function (exports, _emberLocalStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({

    tagName: 'nav',
    classNames: ['webstore'],
    ariaRole: 'navigation',
    storedCart: (0, _emberLocalStorage.storageFor)('current-cart'),
    cartItemsCount: Ember.computed('storedCart.cartItems.length', function () {
      this.runLaterCartItems = Ember.run.later(this, function () {
        $('.webstore .cart-quantity').each(function () {
          $(this).addClass('adding-items');
        });
      }, 100);
      return this.get('storedCart.cartItems.length');
    })
  });
});