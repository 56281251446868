define('hotel-husafell/components/husafell-dashboard', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['husafell-dashboard', 'white-shadow-box'],

    dateTo: (0, _moment.default)().format("YYYY-MM-DD"),
    dateFrom: (0, _moment.default)().startOf('month').subtract(1, 'months').format("YYYY-MM-DD"),
    months: _moment.default.months(),
    years: [],
    defaultDateSelection: {},

    pieChartData: [],
    lineChartData: [],

    observeDates: Ember.observer('dateFrom', 'dateTo', function () {
      this.getAllStats();
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.set("years", function () {
        var years = [];
        var currentYear = parseInt((0, _moment.default)().format("YYYY"));
        for (var i = currentYear - 4; i <= currentYear; i++) {
          years.pushObject(i);
        }
        return years;
      }().reverse());

      this.set("defaultDateSelection.to", {});
      this.set("defaultDateSelection.to.year", (0, _moment.default)(this.get("dateTo")).format("YYYY"));
      this.set("defaultDateSelection.to.month", (0, _moment.default)(this.get("dateTo")).format("MMMM"));

      this.set("defaultDateSelection.from", {});
      this.set("defaultDateSelection.from.year", (0, _moment.default)(this.get("dateFrom")).format("YYYY"));
      this.set("defaultDateSelection.from.month", (0, _moment.default)(this.get("dateFrom")).format("MMMM"));
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);
      this.runScheduler = Ember.run.scheduleOnce('afterRender', this, function () {
        _this.getAllStats();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      Ember.run.cancel(this.runScheduler);
    },
    getAllStats: function getAllStats() {
      var _this2 = this;

      this.set('isLoading', true);
      this.api.getGiftcardDashboardStats(this.get('dateTo'), this.get('dateFrom')).then(function (result) {
        if (result) {
          _this2.set('lineChartData', result.lineChart);
          _this2.set('pieChartData', result.pieChart);
        } else {
          _this2.set('lineChartData', []);
          _this2.set('pieChartData', []);
        }
      }).finally(function () {
        _this2.set('isLoading');
      });
    },


    actions: {
      setToYear: function setToYear(year) {
        var temp = (0, _moment.default)(this.get('dateTo')).year(year).format('YYYY-MM-DD');
        this.set('dateTo', temp);
      },
      setToMonth: function setToMonth(month) {
        var temp = (0, _moment.default)(this.get('dateTo')).month(month).format('YYYY-MM-DD');
        this.set('dateTo', temp);
      },
      setFromYear: function setFromYear(year) {
        var temp = (0, _moment.default)(this.get('dateFrom')).year(year).format('YYYY-MM-DD');
        this.set('dateFrom', temp);
      },
      setFromMonth: function setFromMonth(month) {
        var temp = (0, _moment.default)(this.get('dateFrom')).month(month).format('YYYY-MM-DD');
        this.set('dateFrom', temp);
      }
    }
  });
});