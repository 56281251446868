define('hotel-husafell/controllers/admin/view-giftcard', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({

    hasArrayChange: Ember.computed('model.giftcard.productCounts.@each', "model.giftcard.productCounts.@each.count", 'initialProductCountsArray', function () {
      var currentProductCounts = JSON.stringify(this.get('model.giftcard.productCounts').toArray());
      return currentProductCounts !== this.initialProductCountsArray;
    }),

    saveModel: function saveModel() {
      var _this = this;

      this.get("model.giftcard").save().then(function () {
        if (_this.setUsedCard) {
          _this.get('model.giftcard').setUsedGiftcard().then(function () {
            _this.flashMessages.success("Gjafabréfi hefur verið breytt");
            _this.set('canChangeState', false);
            _this.get('model.giftcard').reload().then(function () {
              _this.cleanUpNewProductCountsRecords();
              _this.set("initialProductCountsArray", JSON.stringify(_this.get('model.giftcard.productCounts').toArray()));
              _this.set('savedChanges', true);
            });
          });
        } else {
          _this.flashMessages.success("Gjafabréfi hefur verið breytt");
          _this.cleanUpNewProductCountsRecords();
          _this.set("initialProductCountsArray", JSON.stringify(_this.get('model.giftcard.productCounts').toArray()));
          _this.set('savedChanges', true);
        }
      }).finally(function () {
        _this.set('isLoading');
      });
    },
    cleanUpNewProductCountsRecords: function cleanUpNewProductCountsRecords() {
      var _this2 = this;

      this.store.peekAll("product-count").forEach(function (d) {
        if (d.isNew) {
          _this2.set("model.giftcard.productCounts", _this2.get("model.giftcard.productCounts").without(d));
          _this2.store.unloadRecord(d);
        }
      });
    },


    actions: {
      saveGiftcard: function saveGiftcard() {
        this.saveModel();
      },
      setCardStatus: function setCardStatus() {
        if (this.get('model.giftcard.state') === 'USED') {
          this.set('model.giftcard.state', 'NOT_USED');
          this.set('setUsedCard', false);
        } else {
          this.set('model.giftcard.state', 'USED');
          this.set('setUsedCard', true);
        }
      },
      continueWithoutSave: function continueWithoutSave() {
        this.set('leaveWithoutSave', true);
        this.set('confirmLeave');
        this.get("model.giftcard").rollbackAttributes();
        this.get('previousTransition').retry();
      },
      continueEditing: function continueEditing() {
        this.set('confirmLeave', false);
      }
    }
  });
});