define('hotel-husafell/components/x-piechart', ['exports', 'd3'], function (exports, _d) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pieChart'],
    didInsertElement: function didInsertElement() {
      // Schedule a call to our `draw` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM.
      this.scheduler = Ember.run.scheduleOnce('afterRender', this, this.draw);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      if (this.visualization !== undefined) {
        // we already rendered the chart once and received this update because our data were changed,
        // let's redraw the whole thing!
        this.scheduler = Ember.run.scheduleOnce('afterRender', this, this.draw);
      }
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduler);
    },
    draw: function draw() {
      this.prepareData();

      var parentElement = _d.default.select("#" + this.elementId),
          parentHeight = parentElement.node().getBoundingClientRect().height,
          radius = parentHeight / 2;

      parentElement.selectAll("*").remove();

      this.visualization = this.appendRootSvgToParent(parentElement, parentHeight, radius);

      var arc = this.createArc(radius);
      var pie = this.createPie();
      var arcs = this.appendArcs(this.visualization, pie, arc);

      this.appendPiePieceLabels(arcs, radius, arc);
      var tooltip = this.createTooltip(parentElement);

      this.addMouseBehaviorToArcs(arcs, tooltip);
    },


    appendRootSvgToParent: function appendRootSvgToParent(parentElement, parentHeight, radius) {
      return parentElement.append("svg:svg").attr("width", parentHeight).attr("height", parentHeight).data([this.data]).append("svg:g").attr("transform", "translate(" + radius + "," + radius + ")");
    },

    createArc: function createArc(radius) {
      return _d.default.arc().innerRadius(0).outerRadius(radius);
    },

    createPie: function createPie() {
      return _d.default.pie().value(function (d) {
        return d.value;
      });
    },

    appendArcs: function appendArcs(visualization, pie, arc) {
      var arcs = visualization.selectAll("g.slice").data(pie).enter().append("svg:g");

      arcs.append("svg:path").attr("class", function (d, i) {
        return "pieChartPiece" + i;
      }).attr("d", arc);

      return arcs;
    },

    appendPiePieceLabels: function appendPiePieceLabels(arcs, radius, arc) {
      arcs.append("svg:text").attr("transform", function (d) {
        d.innerRadius = 0;
        d.outerRadius = radius;
        return "translate(" + arc.centroid(d) + ")";
      }).text(function (d) {
        return d.data.percentage + '%';
      });
    },

    createTooltip: function createTooltip(parentElement) {
      var tooltip = parentElement.append('div').attr('class', 'tooltip');

      tooltip.append('div').attr('class', 'label');

      tooltip.append('div').attr('class', 'count');
      return tooltip;
    },

    addMouseBehaviorToArcs: function addMouseBehaviorToArcs(arcs, tooltip) {
      arcs.on('mouseover', function (d) {
        tooltip.select('.label').html(d.data.label.toUpperCase()).style('color', 'black');
        tooltip.select('.count').html(d.data.value);

        tooltip.style('display', 'block');
        tooltip.style('opacity', 2);
      });

      arcs.on('mousemove', function (d) {
        tooltip.style('top', _d.default.event.layerY + 10 + 'px').style('left', _d.default.event.layerX - 25 + 'px');
      });

      arcs.on('mouseout', function () {
        tooltip.style('display', 'none');
        tooltip.style('opacity', 0);
      });
    },

    prepareData: function prepareData() {
      var totalValue = 0;

      this.data.map(function (d) {
        return totalValue += d.value;
      });
      this.data.map(function (d) {
        return d.percentage = Math.round(d.value / totalValue * 100);
      }, 2);
    }
  });
});