define('hotel-husafell/router', ['exports', 'hotel-husafell/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('login');
    this.route('admin', function () {
      this.route('giftcards', function () {
        this.route('index', { path: '/' });
        this.route('create-giftcard');
        this.route('create-gift-package');
        this.route('view-gift-package', { path: '/:id' });
      });
      this.route('products', function () {
        this.route('index', { path: '/' });
        this.route('cart-items-count');
        this.route('create-product');
        this.route('view-product', { path: '/:id' });
      });
      this.route('search');
      this.route('view-giftcard', { path: '/:id' });
    });
    this.route('webstore', { path: '/' }, function () {
      this.route('cart');
      this.route('detail', { path: '/:id' });
      this.route('purchase-cancelled');
      this.route('purchase-success');
    });
  });

  exports.default = Router;
});