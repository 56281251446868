define('hotel-husafell/models/cart', ['exports', 'ember-data', 'hotel-husafell/mixins/model-validator'], function (exports, _emberData, _modelValidator) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend(_modelValidator.default, {
    cartItems: hasMany('cart-item'),
    payerName: attr('string'),
    payerEmail: attr('string'),

    validations: {
      payerName: {
        presence: true,
        custom: function custom(key, value, _this) {
          return value.trim();
        }
      },

      payerEmail: {
        presence: true,
        custom: function custom(key, value, _this) {
          var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return regex.test(value);
        }
      }
    }
  });
});