define('hotel-husafell/routes/admin/giftcards/view-gift-package', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return Ember.RSVP.hash({
        giftpackage: this.store.findRecord('gift-package', params.id, { reload: true }),
        products: this.store.findAll('product', { reload: true })
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('initialThumbnailArray', JSON.stringify(model.giftpackage.get("thumbnails").toArray()));
      controller.set('initialProductCountsArray', JSON.stringify(model.giftpackage.get("productCounts").toArray()));
      controller.set('leaveWithoutSave', false);
    },


    actions: {
      willTransition: function willTransition(transition) {
        if (!this.controller.get('leaveWithoutSave')) {
          if (this.controller.get('model.giftpackage.hasDirtyAttributes') || this.controller.get('hasArrayChange')) {
            history.forward();
            this.controller.set('confirmLeave', true);
            this.controller.set('previousTransition', transition);
            transition.abort();
          }
        }
      }
    }
  });
});