define('hotel-husafell/components/x-pagination', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['pagination'],

    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      if (this.currentPageIndex === undefined) {
        this.currentPageIndex = 0;
      }
    },


    displayPage: Ember.computed('currentPageIndex', function () {
      return this.currentPageIndex + 1;
    }),

    paginationHasNextPage: Ember.computed("totalPageCount", "displayPage", function () {
      return this.displayPage !== this.totalPageCount;
    }),

    paginationHasPreviousPage: Ember.computed("totalPageCount", "currentPageIndex", function () {
      return this.currentPageIndex !== 0 && this.currentPageIndex <= this.totalPageCount;
    }),

    actions: {
      showNextPage: function showNextPage() {
        this.set("currentPageIndex", this.currentPageIndex + 1);
      },
      showPreviousPage: function showPreviousPage() {
        this.set("currentPageIndex", this.currentPageIndex - 1);
      }
    }
  });
});