define('hotel-husafell/routes/admin/giftcards/create-gift-package', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(transition, params) {
      return Ember.RSVP.hash({
        giftpackage: this.store.createRecord('gift-package'),
        products: this.store.findAll('product')
      });
    }
  });
});