define("hotel-husafell/components/husafell-product", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["husafell-product"],
    possibleTypes: [{
      value: "HOTEL",
      label: "Hótel",
      default: true
    }, {
      value: "RESTAURANT",
      label: "Veitingastaður"
    }, {
      value: "ENTERTAINMENT",
      label: "Afþreying"
    }]
  });
});