define('hotel-husafell/components/x-linechart', ['exports', 'd3', 'moment'], function (exports, _d, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['lineChart'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      // we listen for the ember-resize plugin to tell us when we got resized. If this is the case, we redraw the chart.
      this.get('resizeService').on('debouncedDidResize', function (event) {
        if (_this.visualization !== undefined && _d.default.select("#" + _this.elementId).node() !== null) {
          _this.scheduler = Ember.run.scheduleOnce('afterRender', _this, _this.draw);
        }
      });
    },
    didUpdateAttrs: function didUpdateAttrs() {
      // we already rendered the chart once and received this update because our data were changed,
      // let's redraw the whole thing!
      this.scheduler = Ember.run.scheduleOnce('afterRender', this, this.draw);
    },
    didInsertElement: function didInsertElement() {
      // Schedule a call to our `draw` method on Ember's "render" queue, which will
      // happen after the component has been placed in the DOM.
      this.scheduler = Ember.run.scheduleOnce('afterRender', this, this.draw);
    },
    willDestroyElement: function willDestroyElement() {
      Ember.run.cancel(this.scheduler);
    },
    draw: function draw() {
      if (this.get("data.length") === 0) {
        // if the data is empty, we don't draw anything
        return;
      }

      var parentElement = _d.default.select("#" + this.elementId),
          parentHeight = parentElement.node().getBoundingClientRect().height,
          parentWidth = parentElement.node().getBoundingClientRect().width,
          margin = {
        top: 6,
        left: 32,
        right: 60,
        bottom: 22
      };
      this.MAX_X_LABELS = 10;

      parentElement.selectAll("*").remove();

      this.prepareData();

      var xScale = this.createXScale(parentWidth, margin);
      var yScale = this.createYScale(parentHeight, margin);

      var xAxis = this.createXAxis(xScale);
      var yAxis = this.createYAxis(yScale);

      var valueLine1 = this.createValueLine(xScale, yScale);
      var valueLine2 = this.createValueLine(xScale, yScale);

      // we save the root svg element into this instance so that we can remove it from the dom at a later time if needed
      this.visualization = this.appendRootSvgToParent(parentElement, parentWidth, parentHeight);

      this.appendXAxisToChart(this.visualization, margin, parentHeight, xAxis);
      this.appendYAxisToChart(this.visualization, margin, yAxis);
      this.addMonthLinesToChart(this.visualization, xScale, margin, parentHeight);

      var tooltip = this.createTooltip(parentElement);

      this.appendValueLineToChart(this.visualization, margin, valueLine1, this.data[0].value, "line1");
      this.appendValueLineToChart(this.visualization, margin, valueLine2, this.data[1].value, "line2");

      var circles1 = this.createCircles(this.visualization, xScale, yScale, margin, this.data[0].value, "circle1");
      var circles2 = this.createCircles(this.visualization, xScale, yScale, margin, this.data[1].value, "circle2");

      this.addMouseBehaviorToCircles(circles1, tooltip, yScale, margin, xScale, this.data[0].label);
      this.addMouseBehaviorToCircles(circles2, tooltip, yScale, margin, xScale, this.data[1].label);
    },


    prepareData: function prepareData() {
      for (var i = 0; i < this.get("data.length"); i++) {
        this.get("data").forEach(function (item) {
          return item.value.map(function (d) {
            d.date = (0, _moment.default)(d.date).toDate();
            return d;
          });
        });
      }
    },

    createValueLine: function createValueLine(xScale, yScale) {
      return _d.default.line().curve(_d.default.curveCardinal.tension(0.2)).x(function (d) {
        return xScale(d.date);
      }).y(function (d) {
        return yScale(d.value);
      });
    },

    createXScale: function createXScale(parentWidth, margin) {
      return _d.default.scaleTime().range([0, parentWidth - margin.right]).domain(_d.default.extent(this.data[0].value, function (d) {
        return d.date;
      }));
    },

    createYScale: function createYScale(parentHeight, margin) {
      var maximumValueOfAllDatasets = Math.max(_d.default.max(this.data[0].value, function (d) {
        return d.value;
      }), _d.default.max(this.data[1].value, function (d) {
        return d.value;
      }));
      return _d.default.scaleLinear().range([parentHeight - margin.bottom - margin.top, 0]).domain([0, maximumValueOfAllDatasets]);
    },

    appendRootSvgToParent: function appendRootSvgToParent(parentElement, parentWidth, parentHeight) {
      return parentElement.append("svg:svg").attr("width", parentWidth).attr("height", parentHeight);
    },

    createXAxis: function createXAxis(xScale) {
      var icelandicTimeFormatter = function icelandicTimeFormatter(date) {
        var formattedDate = (0, _moment.default)(date).format("MMM");
        formattedDate = formattedDate.charAt(0).toUpperCase() + formattedDate.substr(1);
        return formattedDate;
      };

      var ticks = this.data[0].value;
      ticks = ticks.map(function (d) {
        return d.date;
      });

      // avoid duplicate if the dataset contains less than e.g. MAX_X_LABELS months
      var previousMonth = "";
      ticks = ticks.filter(function (v, i) {
        var currentMonth = (0, _moment.default)(v).format("MMMM.YYYY");
        if (currentMonth === previousMonth) {
          return false;
        } else {
          previousMonth = currentMonth;
          return true;
        }
      });

      var stride = Math.ceil(ticks.length / this.MAX_X_LABELS);
      ticks = ticks.filter(function (v, i) {
        return i % stride === 0;
      });

      return _d.default.axisBottom(xScale).tickFormat(icelandicTimeFormatter).tickValues(ticks);
    },

    createYAxis: function createYAxis(yScale) {
      return _d.default.axisLeft(yScale).ticks(0);
    },

    appendXAxisToChart: function appendXAxisToChart(visualization, margin, parentHeight, xAxis) {
      visualization.append("svg:g").attr("transform", "translate(" + margin.left + "," + (parentHeight - margin.bottom) + ")").call(xAxis);
    },

    appendYAxisToChart: function appendYAxisToChart(visualization, margin, yAxis) {
      visualization.append("svg:g").attr("transform", "translate(" + margin.left + "," + margin.top + ")").call(yAxis);
    },

    addMonthLinesToChart: function addMonthLinesToChart(visualization, xScale, margin, parentHeight) {
      var currentMonth = void 0;
      this.data[0].value.map(function (item) {
        if (currentMonth !== item.date.getMonth()) {
          currentMonth = item.date.getMonth();
          visualization.append("line").attr("x1", xScale(item.date) + margin.left).attr("x2", xScale(item.date) + margin.left).attr("y1", 0).attr("y2", parentHeight - margin.top - margin.bottom).attr("class", "monthIndicator");
        }
      });
    },

    createTooltip: function createTooltip(parentElement) {
      var tooltip = parentElement.append('div').attr('class', 'tooltip');

      tooltip.append('div').attr('class', 'date');

      tooltip.append('div').attr('class', 'label');

      tooltip.append('div').attr('class', 'value');

      return tooltip;
    },

    appendValueLineToChart: function appendValueLineToChart(visualization, margin, valueline1, dataObject, classToUse) {
      visualization.append("svg:path").data([dataObject]).attr("class", classToUse).attr("transform", "translate(" + margin.left + "," + margin.top + ")").attr("d", valueline1);
    },

    createCircles: function createCircles(visualization, xScale, yScale, margin, dataObject, className) {
      var circles = visualization.selectAll("dot").data(dataObject).enter().append("svg:circle");

      circles.attr("class", className);

      circles.attr("r", 3).attr("cx", function (d) {
        return xScale(d.date) + margin.left;
      }).attr("cy", function (d) {
        return yScale(d.value) + margin.top;
      });

      return circles;
    },

    addMouseBehaviorToCircles: function addMouseBehaviorToCircles(circles, tooltip, yScale, margin, xScale, tooltipLabel) {
      circles.on("mouseover", function (d) {
        _d.default.select(this).classed("selected", true).attr("r", 6);

        tooltip.select('.label').html(tooltipLabel.toUpperCase());
        tooltip.select('.date').html((0, _moment.default)(d.date).format("DD.MM.YYYY"));
        tooltip.select('.value').html(d.value);
        tooltip.style('display', 'block');
        tooltip.style('opacity', 2);
      });

      circles.on("mouseout", function () {
        _d.default.select(this).classed("selected", false).attr("r", 3);
        tooltip.style('display', 'none');
        tooltip.style('opacity', 0);
      });

      circles.on("mousemove", function (d) {
        var tooltipWidth = tooltip.node().getBoundingClientRect().width;
        tooltip.style('top', yScale(d.value) + margin.top + 6 + 'px').style('left', xScale(d.date) + margin.left - tooltipWidth / 2 + 'px');
      });
    }
  });
});