define('hotel-husafell/components/x-product-selection', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = Ember.$;
  exports.default = Ember.Component.extend({
    classNames: ["product-selection"],
    productSelection: [],
    store: Ember.inject.service(),

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get("showProductSelection")) {
        this.updateProductSelection();
      }
    },
    updateProductSelection: function updateProductSelection() {
      var _this = this;

      this.get("productSelection").clear();
      this.availableProducts.map(function (product) {
        var productCount = new Object();
        productCount.count = 1;
        productCount.product = product;
        productCount.isSelectedInProductSelection = false;

        _this.data.forEach(function (d) {
          if (d.get("product.id") === product.id) {
            productCount.id = d.get("id");
            productCount.count = d.get("count");
            productCount.isSelectedInProductSelection = true;
          }
        });

        _this.productSelection.pushObject(productCount);
      });
    },


    actions: {
      openSelection: function openSelection() {
        this.updateProductSelection();
        this.set("showProductSelection", true);
      },


      /**
       * Closes the product selection window and merges the selection data from the popup into the given model (this.data).
       */
      closeSelection: function closeSelection() {
        var _this2 = this;

        this.set("showProductSelection", false);

        this.productSelection.map(function (item) {
          if (item.isSelectedInProductSelection) {
            var productHadAlreadyBeenSelected = false;

            // if the product was already existing in the data, we recycle the old product-count object instead of
            // creating a new one to avoid ember detect an unwanted change on the model.
            _this2.data.forEach(function (d) {
              if (d.get("product.id") === item.product.id) {
                d.set("count", item.count);
                productHadAlreadyBeenSelected = true;
              }
            });

            // if the product had not been selected before, we need to create a new product-count
            // object and add it to the model so that it can be sent to the backend later.
            if (!productHadAlreadyBeenSelected) {
              var productCount = _this2.store.createRecord("product-count");
              if (item.id) {
                productCount.set("id", item.id);
              }
              productCount.set("count", item.count);
              productCount.set("product", item.product);
              _this2.get("data").addObject(productCount);
            }
          } else {
            // if an existing entry in the data had been unselected, we need to remove it from the store and from the model.
            if (item.id) {
              var productCountToRemove = _this2.store.peekRecord('product-count', item.id);
              if (productCountToRemove) {
                _this2.set("data", _this2.get("data").without(productCountToRemove));
                _this2.store.unloadRecord(productCountToRemove);
              }
            } else {
              _this2.data.forEach(function (d) {
                if (d.get("product.id") === item.product.id) {
                  _this2.get("data").removeObject(d);
                  _this2.store.unloadRecord(d);
                }
              });
            }
          }
        });
      },
      showHotelProducts: function showHotelProducts() {
        $("#" + this.elementId + " .restaurantProducts").hide();
        $("#" + this.elementId + " .entertainmentProducts").hide();
        $("#" + this.elementId + " .hotelProducts").show();
        $("#" + this.elementId + " .showEntertainmentProductsButton").removeClass("selected");
        $("#" + this.elementId + " .showRestaurantProductsButton").removeClass("selected");
        $("#" + this.elementId + " .showHotelProductsButton").addClass("selected");
      },
      showRestaurantProducts: function showRestaurantProducts() {
        $("#" + this.elementId + " .hotelProducts").hide();
        $("#" + this.elementId + " .entertainmentProducts").hide();
        $("#" + this.elementId + " .restaurantProducts").show();
        $("#" + this.elementId + " .showHotelProductsButton").removeClass("selected");
        $("#" + this.elementId + " .showEntertainmentProductsButton").removeClass("selected");
        $("#" + this.elementId + " .showRestaurantProductsButton").addClass("selected");
      },
      showEntertainmentProducts: function showEntertainmentProducts() {
        $("#" + this.elementId + " .restaurantProducts").hide();
        $("#" + this.elementId + " .hotelProducts").hide();
        $("#" + this.elementId + " .entertainmentProducts").show();
        $("#" + this.elementId + " .showHotelProductsButton").removeClass("selected");
        $("#" + this.elementId + " .showRestaurantProductsButton").removeClass("selected");
        $("#" + this.elementId + " .showEntertainmentProductsButton").addClass("selected");
      }
    }
  });
});