define('hotel-husafell/components/x-back-link', ['exports', 'hotel-husafell/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var rootURL = _environment.default.rootURL;
  exports.default = Ember.Component.extend({
    classNames: ["x-back-link"],

    actions: {
      navigateBack: function navigateBack() {
        history.back();
      }
    }
  });
});