define('hotel-husafell/controllers/admin/giftcards/view-gift-package', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    hasArrayChange: Ember.computed('model.giftpackage.thumbnails.@each', 'model.giftpackage.productCounts.@each', 'model.giftpackage.productCounts.@each.count', 'initialProductCountsArray', 'initialThumbnailArray', function () {
      var currentImages = JSON.stringify(this.get('model.giftpackage.thumbnails').toArray());
      var currentProductCounts = JSON.stringify(this.get('model.giftpackage.productCounts').toArray());
      return currentImages !== this.initialThumbnailArray || currentProductCounts !== this.initialProductCountsArray;
    }),

    saveModel: function saveModel() {
      var _this = this;

      this.set('isLoading', true);
      this.get("model.giftpackage").save().then(function () {
        _this.cleanUpNewProductCountsRecords();
        _this.get('flashMessages').success("Gjafapakka hefur verið breytt");
        _this.set('initialThumbnailArray', JSON.stringify(_this.get('model.giftpackage.thumbnails').toArray()));
        _this.set('initialProductCountsArray', JSON.stringify(_this.get('model.giftpackage.productCounts').toArray()));
      }).finally(function () {
        _this.set('isLoading');
      });
    },
    cleanUpNewProductCountsRecords: function cleanUpNewProductCountsRecords() {
      var _this2 = this;

      this.store.peekAll("product-count").forEach(function (d) {
        if (d.isNew) {
          _this2.set("model.giftpackage.productCounts", _this2.get("model.giftpackage.productCounts").without(d));
          _this2.store.unloadRecord(d);
        }
      });
    },


    actions: {
      saveGiftPackage: function saveGiftPackage() {
        this.saveModel();
      },
      publishGiftPackage: function publishGiftPackage() {
        this.set("model.giftpackage.visible", true);
        this.saveModel();
      },
      unpublishGiftPackage: function unpublishGiftPackage() {
        this.set("model.giftpackage.visible", false);
        this.saveModel();
      },
      continueWithoutSave: function continueWithoutSave() {
        this.set('leaveWithoutSave', true);
        this.set('confirmLeave', false);
        this.get("model.giftpackage").rollbackAttributes();
        this.get('previousTransition').retry();
      },
      continueEditing: function continueEditing() {
        this.set('confirmLeave', false);
      }
    }
  });
});